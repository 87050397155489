<template>
  <div class="hello">
    Contact
  </div>
</template>

<script>
export default {
  name: 'contactList',
};
</script>
