<template>
  <div class="qrScann">
    <qrcode-stream @decode="onDecode"></qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  name: 'qrScann',
  components: {
    QrcodeStream,
  },
  methods: {
    onDecode(decodedString) {
      alert(decodedString);
    },
  },
};
</script>
