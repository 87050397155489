<template id="main-page">
  <v-ons-page>
    <v-ons-toolbar>
      <div class="center">{{ showTitle }}</div>
    </v-ons-toolbar>
    <transition>
      <keep-alive>
        <component :is="showComponent"></component>
      </keep-alive>
    </transition>
    <v-ons-tabbar swipeable position="auto"
      :tabs="tabs"
      :visible="true"
      :index.sync="activeIndex"
    />
  </v-ons-page>
</template>

<script>
import qrScann from '@/components/qrScann.vue';
import contactList from '@/components/contactList.vue';
import historyList from '@/components/historyList.vue';

export default {
  components: {
    qrScann,
    contactList,
    historyList,
  },
  data() {
    return {
      activeIndex: 0,
      tabs: [
        {
          icon: 'ion-ios-home',
          label: 'History',
          page: 'home',
          key: 'historyList',
        },
        {
          icon: 'ion-ios-home',
          label: 'Scann',
          page: 'home',
          key: 'qrScann',
        },
        {
          icon: 'ion-ios-home',
          label: 'Contact',
          page: 'home',
          key: 'contactList',
        },
      ],
    };
  },
  methods: {
    md() {
      return this.$ons.platform.isAndroid();
    },
  },
  computed: {
    showComponent() {
      return this.tabs[this.activeIndex].key;
    },

    showTitle() {
      return this.tabs[this.activeIndex].label;
    },
  },
};
</script>
