import Vue from 'vue';
import VueOnsen from 'vue-onsenui';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
// Webpack CSS import
import 'onsenui/css/onsenui.css';
import 'onsenui/css/dark-onsen-css-components.css';

// JS import Theme

Vue.use(VueOnsen);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
