<template>
  <div class="hello">
    History
  </div>
</template>

<script>
export default {
  name: 'historyList',
};
</script>
